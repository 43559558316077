import { useEffect, useState } from 'react'
import './Components.css'
import Icon from '@mdi/react'
import { mdiCheck } from '@mdi/js'

function Checkbox(props) {
    const {
        value,
        variant,
        onChange
    } = props
    const [checked, setChecked] = useState(false)
    console.log("post pinned: ", checked);
    
    const onValueChange = () => {
        onChange && onChange(!checked)
        setChecked(!checked)
    }

    useEffect(() => {
        setChecked(value);
    }, [value]);
    
    return (<div {...props} onClick={() => onValueChange()} className={'checkbox ' + variant}>
        <div className='inline'>
            <div className={'checkbox-circle ' + (checked ? "checked" : "")}>
                <Icon path={mdiCheck} className='icon inline small' />
            </div>
        </div>
        <span style={{paddingLeft: "1vh", display: "inline-block"}}>{props.children}</span>
    </div>)
}

export default Checkbox

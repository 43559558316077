import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment/moment"
import '../App.css'
import Card from '../components/Card';
import Error from '../components/Error';
import Footer from '../components/Footer';
import Heading from '../components/Heading';
import Loading from '../components/Loading';
import Chip from '../components/Chip';
import { mdiAccount, mdiBookOpenPageVariant, mdiClock, mdiEye, mdiPencil, mdiPin, mdiPost } from '@mdi/js';
import Button from '../components/Button';
import Icon from '@mdi/react';

function renderPostsList(posts) {

    return posts.map(post => {
        const postTime = moment(post.created_at).format("MMM D, YYYY")
        const editTime = post.updated_at ? moment(post.updated_at).format("MMM D, YYYY") : false
            
        return <Card key={post.id}>
                <Link to={post.section ? `/docs/${post.section}/${post.identifier == "index" ? "" : post.identifier}` : "/post/" + post.id}>
                    <Heading variant="small">{post.title}</Heading>
                </Link>
                <p>{post.description}</p>
                <div>
                    {post.pinned && <Chip icon={mdiPin} label="Pinned"/>}
                    {post.section && <Chip icon={mdiPost} label={post.section.replaceAll("-", " ")}/>}
                    <Chip icon={mdiAccount} label={post.author}/>
                    {post.views && <Chip icon={mdiEye} label={post.views.length.toLocaleString() + " Views"}/>}
                    <br/>
                    <Chip icon={mdiClock} label={postTime}/>
                    {editTime && <Chip icon={mdiPencil} label={"Edited " + editTime}/>}
                </div>
                <br/>
                <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <div>
                        {post.section && <p className='caps'>From <Link to={"/docs/" + post.section}>{post.section.replaceAll("-", " ")}</Link></p>}
                    </div>
                    <Link to={"/post/" + post.id}>
                        <Button icon={mdiBookOpenPageVariant}>Read</Button>
                    </Link>
                </div>
                <br/>
        </Card>
    })
}

function Home() {
    const [loadingStatus, setLoadingStatus] = useState("ready")
    const [posts, setPosts] = useState(false)
    
    const loadPosts = async () => {
        if (loadingStatus == "loading") return
        setLoadingStatus({status: "loading"})
        try {
            const response = await axios.post(`${window.location.protocol || ":"}//${window.location.hostname}:3001/posts`)
            setLoadingStatus({status: "ready"})
            setPosts(response.data)
        } catch (error) {
            console.error(error)
            setLoadingStatus({status: "error", data: error.message})
        }
    }
    
    useEffect(() => {
        loadPosts()
    }, [])
    
    
    return (<div className='landing'>
        

        {{
            loading: <Loading />,
            ready: <></>,
            error: <Error title={"Failed to load posts"} description={loadingStatus.data} center/>
        }[loadingStatus.status]}

        {posts && <>
            <Heading variant="large" icon={mdiPin}>Pinned Posts</Heading>
            {renderPostsList(posts.filter(post => post.pinned))}
            <Heading variant="large">All Posts</Heading>
            {renderPostsList(posts)}
        </>}
        
        <Footer />
    </div>)
}

export default Home;
import './Components.css'
import Icon from '@mdi/react';

function Chip(props) {
    const {
        label,
        icon,
        variant
    } = props

    return (<div style={{display: "inline-block"}}>
        <div className={'chip ' + (variant || "")}>
            {icon && <Icon path={icon} className={'icon small ' + (variant || "")} />}
            <span className={'caps ' + (variant || "")}>{label}</span>
        </div>
    </div>)
}

export default Chip;
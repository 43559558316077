import { mdiFileDocument, mdiFormatLetterCase, mdiIdCard, mdiPin, mdiPost, mdiPostOutline, mdiText } from '@mdi/js'
import Heading from "../components/Heading"
import Input from "../components/Input"
import InputGroup from "../components/InputGroup"
import TextArea from '../components/TextArea'
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';
import { useContext, useEffect, useState } from 'react'
import Checkbox from '../components/Checkbox'
import { AppContext } from '../scripts/AppContext'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import axios from 'axios'
import Chip from '../components/Chip'
import Card from '../components/Card'

function SubmitPost() {
    const { loginInfo } = useContext(AppContext)
    const navigator = useNavigate()
    
    const [loading, setLoading] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [postTitle, setPostTitle] = useState("")
    const [postSubtitle, setPostSubtitle] = useState("")
    const [postSection, setPostSection] = useState(false)
    const [postPinned, setPostPinned] = useState(false)
    const [postSectionName, setPostSectionName] = useState(false)
    const [postIdentifier, setPostIdentifier] = useState(false)
    const [postContent, setPostContent] = useState("")

    const postFormSubmit = async (event) => {
        event.preventDefault()
        setLoading("Submitting...")
        setErrorMessage("")
        
        const postToSend = {
            title: postTitle,
            description: postSubtitle,
            content: postContent,
            pinned: postPinned,
            section: postSection ? postSectionName : false,
            identifier: postSection ? postIdentifier : false
        }

        // console.log("Post to send:", postToSend)

        try {
            const response = await axios.post(`${window.location.protocol || ":"}//${window.location.hostname}:3001/submit`, postToSend, {
                headers: {
                    Authorization: `Bearer ${loginInfo.token}`
                }
            })
            
            
            // console.log(response.data.post.id)
            navigator(postSection ? `/docs/${postSectionName}/${postIdentifier || ""}` : "/post/" + response.data.post.id)
        } catch (error) {
            setErrorMessage(error.response?.data?.message || error.message)
            console.error(error)
        }
        setLoading(false)
    }
    
    useEffect(() => {
        if (loginInfo.token === null) navigator('/login')
    }, [])

    useEffect(() => {
        if (postSection && postSectionName === false) {
            setPostSectionName("")
            setPostIdentifier("")
        }
    }, [postSection])
    
    return (<div>
        <Heading variant='large'>Submit a post</Heading>
        <form onSubmit={postFormSubmit}>
            <InputGroup>
                <Input required value={postTitle} onChange={(event) => setPostTitle(event.target.value)} type="text" placeholder={"Post title"} icon={mdiFormatLetterCase} variant="full-width"/>
                <Input required value={postSubtitle} onChange={(event) => setPostSubtitle(event.target.value)} type="text" placeholder={"Post subtitle"} icon={mdiText} variant="full-width"/>
            </InputGroup>
            <br/>
            <Checkbox value={postPinned}onChange={setPostPinned}>
                <Chip icon={mdiPin} label="Pinned post" variant={postPinned ? "primary" : ""} />
            </Checkbox>
            <Checkbox value={postSection}onChange={setPostSection}>
                <Chip icon={mdiFileDocument} label="Section post" variant={postSection ? "primary" : ""} />
            </Checkbox>
            {postSection && <Card>
                <p className='caps'>Section settings</p>
                <InputGroup style={{alignItems: "flex-start"}}>
                    <Input value={postSectionName} onChange={(event) => setPostSectionName(event.target.value.replace(" ", "-"))} required type="text" placeholder={"Section name"} icon={mdiPostOutline} variant="full-width"/>
                    <Input value={postIdentifier} onChange={(event) => setPostIdentifier(event.target.value.replace(" ", "-"))} type="text" placeholder={"Post identifier"} icon={mdiIdCard} variant="full-width"/>
                </InputGroup>
                <p>Leave identifier blank to save this post as section page.</p>
            </Card>}
            <p className='caps'>Post content</p>
            {/* <TextArea variant="full-width" resize="none"/> */}
            <Editor
                value={postContent}
                onValueChange={(text) => setPostContent(text)}
                highlight={code => highlight(code, languages.html)}
                className='input'
                padding={16}
                textareaClassName='padded'
                style={{
                    fontFamily: 'monospace',
                    outline: 'none',
                }}
                required
            />

            <br/>
            <div className='flex-center' style={{justifyContent: "space-between"}}>
                <Link to="/login"><Button disabled={loading} variant="neutral">Back</Button></Link>
                <Button disabled={loading} variant="success" icon={mdiPost}>{loading || "Submit"}</Button>
            </div>
        </form>
    </div>)
}

export default SubmitPost
import '../App.css'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import LoadedPost from '../components/LoadedPost';
import Loading from '../components/Loading';
import Error from '../components/Error';
import { AppContext } from '../scripts/AppContext';

function ViewPost() {
    const { id, section, identifier } = useParams()
    const { loginInfo } = useContext(AppContext)
    const [loadingStatus, setLoadingStatus] = useState({status: "ready"})
    const [post, setPost] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {        
        if (!id && !section) {
            navigate("/submit")
        }
    })
    
    useEffect(() => {
        if (loadingStatus === "loading") return
        setLoadingStatus({status: "loading"})
        setPost(false)
        if (id || section) {
            const sectionData = section ? {section, identifier: identifier || false} : {}
            
            axios.post(
                id ? `${window.location.protocol || ":"}//${window.location.hostname}:3001/posts/`+id
                : `${window.location.protocol || ":"}//${window.location.hostname}:3001/section-posts`, {
                viewerId: loginInfo.id || JSON.parse(localStorage.getItem('thomasblog'))?.viewerId || null,
                ...sectionData
            }).then(res => {
                setLoadingStatus({status: "ready"})
                setPost(res.data)

                if (res.data.viewerId && !(JSON.parse(localStorage.getItem('thomasblog'))?.viewerId)) {
                    // console.log("ViewerID acquired: ", res.data.viewerId)
                    let newLocalStorageData = {
                        viewerId: res.data.viewerId,
                        ...JSON.parse(localStorage.getItem('thomasblog') || '[]')
                    }
                    
                    localStorage.setItem("thomasblog", JSON.stringify(newLocalStorageData))
                    
                }
            }).catch(err => {
                console.error(err)
                setLoadingStatus({status: "error", data: err.message})
            })
        }
    }, [id, section, identifier])

    return (<div>

        {{
            loading: <Loading />,
            ready: <></>,
            error: <Error title={"Failed to load post"} description={loadingStatus.data} center/>
        }[loadingStatus.status]}

        {post && <LoadedPost post={post} section={section} identifier={identifier} />}
        <Footer />
    </div>)
}

export default ViewPost;
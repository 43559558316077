import '../App.css'
import '../Landing.css'
import { Link } from 'react-router-dom';
import Heading from '../components/Heading';
import { mdiChevronDown, mdiDatabase, mdiExitToApp, mdiNodejs, mdiOpenInApp, mdiOpenInNew, mdiReact, mdiWeb } from '@mdi/js';
import Chip from '../components/Chip';
import Button from '../components/Button';
import Footer from '../components/Footer';
import { useEffect, useState } from 'react';
import Icon from '@mdi/react';

function TextCursor({ animating }) {
    return <span className={'cursor ' + (animating && "animating")}>|</span>
}

function TypingEffect(props) {
    const targetTexts = props.label
    const [targetTextIndex, setTargetTextIndex] = useState(0)
    const [currentText, setCurrentText] = useState("")
    const [selectedText, setSelectedText] = useState("")
    const [currentIndex, setCurrentIndex] = useState(0)
    const [cursorAnimating, setCursorAnimating] = useState(false)
    const [currentStage, setCurrentStage] = useState("typing")
    
    
    const selectCharacter = () => {
        if (currentIndex == 0) {
            setTargetTextIndex(targetTextIndex + 1)
            if (targetTextIndex+1 >= targetTexts.length) setTargetTextIndex(0)
            setTimeout(() => {
                setSelectedText("")
                setCurrentStage("typing")
                setCursorAnimating(false)
            }, 1000)
            setCursorAnimating(true)
            return
        }
        
        setSelectedText(currentText[currentIndex - 1] + selectedText)
        setCurrentText(currentText.substring(0, currentText.length - 1))
        setCurrentIndex(currentIndex - 1)
    }
    
    const addCharacter = () => {
        if (targetTexts[targetTextIndex].length <= currentIndex) {
            setTimeout(() => {
                setCurrentStage("selecting")
                setCursorAnimating(false)
            }, 1000 + (targetTextIndex+1 >= targetTexts.length ? 2000 : 0))
            setCursorAnimating(true)
            return
        }

        setCurrentText(currentText + targetTexts[targetTextIndex][currentIndex])
        setTimeout(() => {
            setCurrentIndex(currentIndex + 1)
        }, 1 + (targetTexts[targetTextIndex][currentIndex] == "." ? 150 : 0))
    }
    
    useEffect(() => {
        if (currentStage == "typing")
            setTimeout(addCharacter, 70)
        else if (currentStage == "selecting")
            setTimeout(selectCharacter, 1 + ((selectedText.length/targetTexts[targetTextIndex].length) * (selectedText.length/targetTexts[targetTextIndex].length) * 70))
    }, [currentIndex, currentStage])
    
    return (<>
        {currentText}
        <TextCursor animating={cursorAnimating} />
        {selectedText.length > 0 && <span className='selected' style={{fontSize: "inherit"}}>{selectedText}</span>}
    </>)
}

function ColorAnimation() {
    const [scrolledIntoView, setScrolledIntoView] = useState(false)
    
    const checkScrollListener = (event) => {
        if ((window.scrollY > (window.innerHeight*0.5)) && !scrolledIntoView) {
            setScrolledIntoView(true)
            document.removeEventListener("scroll", checkScrollListener)
        }
    }

    document.addEventListener("scroll", checkScrollListener)
    
    return (<div className={'landing section ' + (scrolledIntoView && "color-animation")}>
        <Heading variant="landing">what?</Heading>
        <p>
            Coding stuff. That's what I intend to fill this with.
            <br />
            Also sit here and watch this animation I made.
        </p>
        <div className='mouse-cursor'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="3vh" height="3vh">
                <path
                    fill="white"
                    stroke='grey'
                    d="M 9 3 A 1 1 0 0 0 8 4 L 8 21 A 1 1 0 0 0 9 22 A 1 1 0 0 0 9.796875 21.601562 L 12.919922 18.119141 L 16.382812 26.117188 C 16.701812 26.855187 17.566828 27.188469 18.298828 26.855469 C 19.020828 26.527469 19.340672 25.678078 19.013672 24.955078 L 15.439453 17.039062 L 21 17 A 1 1 0 0 0 22 16 A 1 1 0 0 0 21.628906 15.222656 L 9.7832031 3.3789062 A 1 1 0 0 0 9 3 z" />
            </svg>
        </div>
        <div className='color-pallete'>
            <div style={{backgroundColor: 'red'}} />
            <div style={{backgroundColor: 'blue'}} />
            <div style={{backgroundColor: 'green'}} />
            <div style={{backgroundColor: 'yellow'}} />
            <div style={{backgroundColor: 'white'}} />
        </div>
    </div>)
}

function AnimatingArrow() {
    return (<Icon className='icon animating-icon' path={mdiChevronDown} />)
}

function About() {
    return (<div className='about'>
        <div className='landing section'>
            <Heading variant="landing">
                <TypingEffect
                    label={[
                        "thomascatt's blog.",
                        "a place to dump my thoughts... maybe?",
                        "my tuts go here...",
                        "...",
                        "scroll down already..."
                    ]}
                />
            </Heading>
            <p>A blog I made with my very own hands. For me.</p>
            <AnimatingArrow />
        </div>

        <ColorAnimation />

        <div className='landing section'>
            <Heading variant="landing">what do i do?</Heading>
            <p>Well I code. In JavaScript.</p>
            <p>I'm not good at CSS.</p>
        </div>

        <div className='card'>
            <Heading variant="small">What is this?</Heading>
            <p>Supposed to be a cleaner implementation for my documents, they've gotten really messy.</p>
            <p>Not much to do here actually, you can visit the posts page to start reading the blogs, and that is mostly it. Also I might add users later.</p>
        </div>
        
        <div className='card'>
            <Heading variant="small">Technologies</Heading>
            <p>Here's what this app is collectively using as of now:</p>
            <ul>
                <li><Chip icon={mdiDatabase} label="MongoDB Atlas" /></li>
                <li><Chip icon={mdiWeb} label="Express JS" /></li>
                <li><Chip icon={mdiReact} label="React JS" /></li>
                <li><Chip icon={mdiNodejs} label="Node JS" /></li>
            </ul>
        </div>
        

        <div className='landing section'>
            <Heading variant="landing">where's the blog then?</Heading>
            <p>Here it is, have a look:</p>
            <Link to="/posts"><Button icon={mdiExitToApp} variant={"primary"}>Go to the Blog!</Button></Link>
        </div>


        <Footer />
    </div>)
}

export default About;
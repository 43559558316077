import './Components.css'

function Footer() {

    return (<center>
        <br />
        <p className='caps'>By ThomasCatt - <a href='https://github.com/thomas-catt' rel="noreferrer" target="_blank">GitHub</a></p>
    </center>)
}

export default Footer;
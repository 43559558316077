import './Components.css'
import Icon from '@mdi/react';

function Heading(props) {
    const {
        variant,
        inline,
        icon
    } = props

    return (<div className={'heading ' + (variant || "normal")} style={{display: inline ? "inline-block" : (icon ? "flex" : "block"), justifyContent: "flex-start", alignItems: "center"}}>
        {icon && <Icon path={icon} className={'icon ' + (variant || "")} />}
        {props.children}
    </div>)
}

export default Heading;
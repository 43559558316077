import { mdiCheck, mdiFormatLetterCase, mdiIdCard, mdiPin, mdiPost, mdiPostOutline, mdiText } from '@mdi/js'
import Heading from "../components/Heading"
import Input from "../components/Input"
import InputGroup from "../components/InputGroup"
import TextArea from '../components/TextArea'
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';
import { useContext, useEffect, useState } from 'react'
import Checkbox from '../components/Checkbox'
import { AppContext } from '../scripts/AppContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '../components/Button'
import axios from 'axios'
import Loading from '../components/Loading'
import Chip from '../components/Chip'

function EditPost() {
    const { id } = useParams()
    const { loginInfo } = useContext(AppContext)
    const navigator = useNavigate()
    
    const [dataLoading, setDataLoading] = useState(true)
    const [loading, setLoading] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [postTitle, setPostTitle] = useState("")
    const [postSubtitle, setPostSubtitle] = useState("")
    const [postPinned, setPostPinned] = useState(false)
    const [postSection, setPostSection] = useState(false)
    const [postSectionName, setPostSectionName] = useState(false)
    const [postIdentifier, setPostIdentifier] = useState(false)
    const [postContent, setPostContent] = useState("")

    const postFormSubmit = async (event) => {
        event.preventDefault()
        setLoading("Updating")

        try {
            const response = await axios.post(`${window.location.protocol || ":"}//${window.location.hostname}:3001/edit`, {
                id: id,
                title: postTitle,
                description: postSubtitle,
                pinned: postPinned,
                content: postContent,
            }, {
                headers: {
                    Authorization: `Bearer ${loginInfo.token}`
                }
            })
            
            // console.log(response.data.post.id)
            navigator(postSection ? `/docs/${postSectionName}/${postIdentifier || ""}` : "/post/" + response.data.post.id)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }
    
    useEffect(() => {
        if (loginInfo.token === null) navigator('/login')
    }, [])

    useEffect(() => {
        setDataLoading(false)
        if (id) {
            axios.post(`${window.location.protocol || ":"}//${window.location.hostname}:3001/posts/${id}`, {
                viewerId: loginInfo.id || JSON.parse(localStorage.getItem('thomasblog'))?.viewerId || null,
            }).then(res => {
                const post = res.data

                setDataLoading(true)
                setPostTitle(post.title)
                setPostSubtitle(post.description)
                setPostContent(post.content)
                setPostSection(post.section)
		        setPostPinned(post.pinned || false)
                setPostSectionName(post.section)
                setPostIdentifier(post.identifier)
            }).catch(err => {
                console.error(err)
            })
        }
    }, [id])

    return (<div>
        <Heading variant='large'>Edit post</Heading>
        <form onSubmit={postFormSubmit} hidden={!dataLoading}>
            <InputGroup>
                <Input required value={postTitle} onChange={(event) => setPostTitle(event.target.value)} type="text" placeholder={"Post title"} icon={mdiFormatLetterCase} variant="full-width"/>
                <Input required value={postSubtitle} onChange={(event) => setPostSubtitle(event.target.value)} type="text" placeholder={"Post subtitle"} icon={mdiText} variant="full-width"/>
            </InputGroup>
            <br/>
            <Checkbox value={postPinned} onChange={setPostPinned}>
                <Chip icon={mdiPin} label="Pinned post" variant={postPinned ? "primary" : ""} />
            </Checkbox>
            <p className='caps'>Post content</p>
            <Editor
                value={postContent}
                onValueChange={(text) => setPostContent(text)}
                highlight={code => highlight(code, languages.html)}
                className='input'
                padding={16}
                textareaClassName='padded'
                style={{
                    fontFamily: 'monospace',
                    outline: 'none',
                }}
                required
            />

            <br/>
            <div className='flex-center' style={{justifyContent: "space-between"}}>
                <Link to={postSection ? `/docs/${postSectionName}/${postIdentifier || ""}` : "/post/" + id}><Button disabled={loading} variant="neutral">Back</Button></Link>
                <Button disabled={loading} variant="success" icon={mdiCheck}>{loading || "Update Post"}</Button>
            </div>
        </form>

        <Loading hidden={dataLoading} label="Fetching..." />
    </div>)
}

export default EditPost

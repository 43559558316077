import './Components.css'
import Icon from '@mdi/react';

function Input(props) {
    const {
        placeholder,
        variant,
        icon,
        sideelement
    } = props

    return (<div className={'input inline ' + (variant || "")}>
        <div>
            {icon ? <Icon path={icon} className={'icon'} /> : (sideelement)}
            <input {...props} className={variant} placeholder={placeholder}/>
        </div>
    </div>)
}

export default Input;
import '../App.css'
import { Link } from 'react-router-dom';
import Heading from '../components/Heading';
import Icon from '@mdi/react';
import { mdiAccount, mdiAccountCircle, mdiClose, mdiHome, mdiMenu, mdiPost } from '@mdi/js';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../scripts/AppContext';
import Chip from './Chip';

function Header() {
    let collapsible = window.innerWidth <= 1000
    const [menuVisible, setMenuVisible] = useState(!collapsible)
    const { loginInfo } = useContext(AppContext)
    
    const toggleCollapse = () => {
        if (collapsible) setMenuVisible(!menuVisible)
    }
    
    return (<div className='header'>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "centers"}}>
            <Link to="/">
                <Heading variant="logo" inline>
                    ThomasBlog
                </Heading>
            </Link>
            <Icon path={menuVisible ? mdiClose : mdiMenu} className='icon large menu-button' onClick={toggleCollapse} style={{float: "right"}} />
        </div>
        <div className='menu-items flex-center' style={{display: !menuVisible && "none"}}>
            <Link onClick={toggleCollapse} to={"/"}>
                <Icon path={mdiHome} className='icon menu-items-icon' />
                Home &nbsp;
            </Link>
            <Link onClick={toggleCollapse} to={"/posts/"}>
                <Icon path={mdiPost} className='icon menu-items-icon' />
                Blog &nbsp;
            </Link>
            {loginInfo.token ?
            <Link onClick={toggleCollapse} to={"/login"}>
                <Chip icon={mdiAccountCircle} label="thomascatt" />
            </Link>
            :<Link onClick={toggleCollapse} to={"/login"}>
                <Icon path={mdiAccount} className='icon menu-items-icon' />
                Login &nbsp;
            </Link>}
        </div>
    </div>)
}

export default Header;
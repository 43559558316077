import './App.css';
import './Variables.css';
import Demo from './pages/Demo';
import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';
import { Route, Routes, BrowserRouter, useNavigate } from "react-router-dom";
import Header from './components/Header';
import ViewPost from './pages/ViewPost';
import { AppContext } from './scripts/AppContext';
import { useEffect, useState } from 'react';
import Login from './pages/Login';
import axios from 'axios';
import SubmitPost from './pages/SubmitPost';
import EditPost from './pages/EditPost';

function App() {
	const defaultLoginInfo = {
		id: null,
		username: null,
		token: null
	}
	const [loginInfo, setLoginInfo] = useState({
		...defaultLoginInfo,
		...(JSON.parse(localStorage.getItem("thomasblog") || "false") || defaultLoginInfo)
	})
	
	useEffect(() => {
		if (loginInfo.token === null) return
		
		(async () => {
			try {
				const response = await axios.post(`${window.location.protocol || ":"}//${window.location.hostname}:3001/login/verify`, {}, {
					headers: {
						Authorization: `Bearer ${loginInfo.token}`
					}
				})
				// console.log(response.status)
			} catch (error) {
				if (error.response.status === 401) {
					console.log("Session expired. Please log in again.")
					setLoginInfo(defaultLoginInfo)
					localStorage.removeItem('thomasblog')
				} else
					console.error(error)
			}
		})()
	}, [])
	
	return (<div className='app'>
		<BrowserRouter basename="/">
			<AppContext.Provider value={{
				loginInfo,
				setLoginInfo,
				defaultLoginInfo
			}}>
				<Header />
				<Routes>
					<Route path="/posts/" element={<Home />} />
					<Route path="/post/:id?" element={<ViewPost />} />
					<Route path="/docs/:section/:identifier?" element={<ViewPost />} />
					<Route path="/demo" element={<Demo />} />
					<Route path="/" element={<About />} />
					<Route path="/login" element={<Login />} />
					<Route path="/submit" element={<SubmitPost />} />
					<Route path="/edit/:id" element={<EditPost />} />
//					<Route path="*" element={<NotFound />} />
				</Routes>
			</AppContext.Provider>
		</BrowserRouter>
	</div>);
}
	
export default App;

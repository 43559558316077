import './Components.css'

function Card(props) {
    const {
        variant
    } = props

    return (<div className={'card ' + (variant || "")}>
        {props.children}
    </div>)
}

export default Card;
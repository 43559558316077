import '../App.css'
import Footer from '../components/Footer';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../scripts/AppContext';
import Heading from '../components/Heading';
import Input from '../components/Input';
import Button from '../components/Button';
import Loading from '../components/Loading';
import Error from '../components/Error';
import { mdiAccount, mdiAccountCircle, mdiAt, mdiEmail, mdiExitToApp, mdiFormTextboxPassword, mdiLogin, mdiOpenInNew } from '@mdi/js';
import InputGroup from '../components/InputGroup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Card from '../components/Card';

function Login() {
    const { loginInfo, setLoginInfo, defaultLoginInfo } = useContext(AppContext)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const submitLoginForm = async (event) => {
        event.preventDefault()
        setLoading("Logging in...")
        setErrorMessage("")
        
        try {
            const response = await axios.post(`${window.location.protocol || ":"}//${window.location.hostname}:3001/login`, {
                username,
                password
            })
            
            // console.log(response.data)
            
            setLoginInfo(response.data)
            const prevLocalStorage = JSON.parse(localStorage.getItem('thomasblog') || '[]')
            
            localStorage.setItem("thomasblog", JSON.stringify({...prevLocalStorage, ...response.data}))
        } catch (error) {
            console.error(error)
            setErrorMessage(error.response.data.message)
        }
        setLoading(false)
    }

    const logOut = () => {
        if (!window.confirm("Press OK to proceed to log out")) return
        setLoginInfo(defaultLoginInfo)
        const viewerId = JSON.parse(localStorage.getItem('thomasblog'))?.viewerId || false
        
        localStorage.removeItem('thomasblog')
        if (viewerId) localStorage.setItem('thomasblog', JSON.stringify({viewerId}))
    }
    
    return (<div>
        <div className='flex-center column' style={{padding: "15% 0"}}>
            {!loginInfo.token ? <form onSubmit={submitLoginForm} className='mobile-size flex-center column'>
                <Heading>Login to ThomasBlog</Heading>
                <br/>
                <InputGroup>
                    <Input required placeholder={"Username"} type="text" onChange={(event) => setUsername(event.target.value)} icon={mdiAccount}/>
                    <Input required placeholder={"Password"} type="password" onChange={(event) => setPassword(event.target.value)} icon={mdiFormTextboxPassword}/>
                </InputGroup>
                <div className='padded'>
                    <Loading hidden={!loading}/>
                    {errorMessage && <Error title={errorMessage} />}
                </div>
                <Button icon={mdiLogin} disabled={loading}>{loading || "Login"}</Button>
            </form> : <>
            <Card variant="small flex-center column padded">
                <Heading variant="small no-margin">Logged in as {loginInfo.username}</Heading>
                <Button onClick={logOut} icon={mdiExitToApp}>Log out</Button>
            </Card>
            <Card variant="small flex-center column padded">
                <Heading variant="small no-margin">Submit a post</Heading>
                <Button onClick={() => navigate('/submit')} icon={mdiOpenInNew}>Go to Submit</Button>
            </Card>
            </>}
        </div>
        <Footer />
    </div>)
}

export default Login;
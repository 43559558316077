import Chip from "./Chip"
import Heading from "./Heading"
import moment from "moment/moment"
import { mdiAccount, mdiDelete, mdiChevronLeft, mdiClock, mdiContentCopy, mdiPencil, mdiPost, mdiShare, mdiEye, mdiPin } from "@mdi/js"
import Button from "./Button"
import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Icon from "@mdi/react"
import axios from "axios"
import Card from "./Card"
import { AppContext } from "../scripts/AppContext"


function ShareButton() {
    const [text, setText] = useState("Share this page")
    const [icon, setIcon] = useState(mdiShare)
    
    const shareButtonClick = () => {
        try {
            navigator.clipboard.writeText(window.location.href)
            setText("Link copied!")
            setIcon(mdiContentCopy)
        } catch (err) {
            alert("Couldn't copy link, unsupported browser.")
        }
    }
    
    return <Button onClick={shareButtonClick} icon={icon} variant="neutral">{text}</Button>
}

function LoadedPost({post, section, identifier}) {
    const postTime = moment(post.created_at).format("MMM D, YYYY")
    const navigator = useNavigate()
    const editTime = post.updated_at ? moment(post.updated_at).format("MMM D, YYYY") : false
    const [sectionPosts, setSectionPosts] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    
    const { loginInfo } = useContext(AppContext)
    
    var sectionPage = section && !identifier
    
    useEffect(() => {
        if (!section || identifier)
            sectionPosts && setSectionPosts(false)
        else (async () => {
            try {
                const response = await axios.post(`${window.location.protocol || ":"}//${window.location.hostname}:3001/section/` + section)
                

                // console.log(response)
                setSectionPosts(response.data)
            } catch (error) {
                console.error(error)
            }
        })()
    }, [section, identifier])

    const deletePost = async () => {
        if (!window.confirm("Are you sure that you want to delete this post? You can't undelete it later!") || !window.confirm("Are you sure that you want to delete this post? You can't undelete it later!\n\n[REALLY SURE?]")) return

        setDeleteLoading("Deleting...")
        
        try {
            const response = await axios.delete(`${window.location.protocol || ":"}//${window.location.hostname}:3001/delete/` + post.id, {
                headers: {
                    Authorization: `Bearer ${loginInfo.token}`
                }
            })
            
            alert("Post deleted :(")
            navigator("/posts/")
        } catch (error) {
            console.error(error)
        }
        setDeleteLoading(false)
    }
    
    
    return <div>
        <div className="inline">
            <Link to={'/posts'} className="inline flex-center">
                <Icon path={mdiChevronLeft} className='icon inline'/>
                <span>Back</span>
            </Link>
        </div>
        {sectionPage ? <Card>
            <div className={'landing flex-center column'}>
                <Heading variant="landing">{post.title}</Heading>
                <p>{post.description}</p>
            </div>
        </Card> : <div>
            <div className={'landing '}>
                <Heading variant="landing">{post.title}</Heading>
                <p>{post.description}</p>
            </div>
            <div>
                {post.pinned && <Chip icon={mdiPin} label="Pinned"/>}
                {(section && identifier) && <Link to={"/docs/" + section}><Chip icon={mdiPost} label={section.replaceAll("-", " ")}/></Link>}
                <Chip icon={mdiAccount} label={post.author}/>
                {post.views && <Chip icon={mdiEye} label={post.views.length.toLocaleString() + " Views"}/>}
                <br/>
                <Chip icon={mdiClock} label={postTime}/>
                {editTime && <Chip icon={mdiPencil} label={"Edited " + editTime}/>}
            </div>
            <br/>
            {loginInfo.token && <Link to={"/edit/" + post.id}><Button icon={mdiPencil} variant="secondary">Edit</Button></Link>}
            {loginInfo.token && <Button icon={mdiDelete} variant="danger" disabled={deleteLoading} onClick={deletePost}>{deleteLoading || "Delete"}</Button>}
        </div>}
        <div className="post-content" dangerouslySetInnerHTML={{ __html: post.content }} />
        <br/>
        <div>
            
            {sectionPosts && <>
                <Heading variant="landing">In {section.replaceAll("-", " ")}</Heading>
                {sectionPosts.map(item => {
                    const postTime = moment(item.created_at).format("MMM D, YYYY")
                
                    return <Card key={item.id}>
                        <Link to={item.section ? `/docs/${item.section}/${item.identifier == "index" ? "" : item.identifier}` : "/post/" + item.id}>
                            <Heading variant="small no-margin">{item.title}</Heading>
                            <p>{item.description}</p>
                            <div>
                                {item.section && <Chip icon={mdiPost} label={item.section.replaceAll("-", " ")}/>}
                                <Chip icon={mdiAccount} label={item.author}/>
                                <Chip icon={mdiClock} label={postTime}/>
                            </div>
                        </Link>
                        <br/>
                </Card>
                })}
            </>}
        </div>
        <ShareButton />
    </div>
}

export default LoadedPost
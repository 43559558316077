import { Link } from 'react-router-dom';
import '../App.css'
import Heading from '../components/Heading';

function NotFound() {
    return (<div className='landing center'>
        <Heading variant="landing">Nowhere to be found...</Heading>
        <span className='caps'>This is the 404 you're looking for. Stop messing with my site.</span>
        <br/>
        <br/>
        <Link to="/posts/">Visit the home page maybe?</Link>
    </div>)
}

export default NotFound;
import './Components.css'
import Icon from '@mdi/react';
import { mdiAlert } from '@mdi/js';

function Error(props) {
    const {
        title,
        description,
        center
    } = props

    return (<div style={{display: "inline-block", margin: center ? "auto" : undefined}}>
        <div className={'error-chip'}>
            <Icon path={mdiAlert} className={'icon'} />
            <b>{title}</b>
            <span>{description ? <>&nbsp;{description}</> : ""}</span>
        </div>
    </div>)
}

export default Error;
import './Components.css'
import Heading from './Heading';

function Loading(props) {
    const {
        label,
        inline
    } = props

    return (<center style={{width: inline ? "initial" : "100%"}}  {...props}>
        <div className={'loader'}>
            <Heading variant="tiny">{label || ""}</Heading>
            <div className="loader-dot l" />
            <div className="loader-dot m" />
            <div className="loader-dot r" />            
        </div>
    </center>)
}

export default Loading;
import { Link } from 'react-router-dom';
import '../App.css'
import Button from '../components/Button';
import Icon from '@mdi/react';
import { mdiAccount, mdiClock, mdiDelete, mdiNodejs, mdiSend, mdiAt } from '@mdi/js';
import Chip from '../components/Chip';
import Card from '../components/Card';
import Heading from '../components/Heading';
import Input from '../components/Input';
import Loading from '../components/Loading';
import Footer from '../components/Footer';
import Checkbox from '../components/Checkbox';

function Demo() {
    return (<>
        <Heading variant="landing">Demo page</Heading>
		<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste dolor fugiat esse impedit deleniti, accusantium neque culpa nihil, consequuntur facere earum magni quae ad nisi saepe asperiores sequi harum tempore.</p>
        <p className='caps'>This is some demo text in caps.</p>
        <div>
            <Button>Hello World</Button>
            <Button variant={"secondary"} >Goodbye</Button>
        </div>
        <div>
            <Button variant={"neutral"} >White!</Button>
        </div>
        <div>
            <Button icon={mdiAccount}>With an Icon</Button>
            <Button icon={mdiDelete} variant="danger">Delete (dangerous)</Button>
            <Button icon={mdiSend} variant="success">Submit</Button>
        </div>

        <div>
            <Icon path={mdiClock} className={'icon small'}/>
            <Icon path={mdiClock} className={'icon'}/>
            <Icon path={mdiClock} className={'icon large'}/>
            <Icon path={mdiClock} className={'icon huge'}/>
        </div>

        <div>
            <Chip label="Today" icon={mdiClock} />
            <Chip label="thomascatt" icon={mdiAccount} />
            <Chip label="NodeJS" icon={mdiNodejs} />
        </div>

        <div>
            <p>Here's some headings!</p>
            <Heading variant="small">Tiny heading!</Heading>
            <Heading>Normal heading!</Heading>
            <Heading variant="large">Large heading!</Heading>
            <Heading variant="landing">Enormous heading!</Heading>

        </div>

        <div>
            <Heading>Input field:</Heading>
            <Input placeholder={"Enter something"} icon={mdiAt}/>
        </div>

        <Card>
            <Heading>This is a card!</Heading>
            <div>
                <Chip label="Today" icon={mdiClock} />
                <Chip label="thomascatt" icon={mdiAccount} />
                <Chip label="NodeJS" icon={mdiNodejs} />
            </div>
            <p>It's good to contain some separated information!</p>
        </Card>

        <Card variant="small">
            <Heading>This is a smaller card!</Heading>
            <p>It's the smaller variant of the above card and can contain small bits of information!</p>
            <p>This should appear as a normal card on mobile screens!</p>
        </Card>

        <div>
            <Loading label="Loading..." />
        </div>

        <div>
            <Checkbox>Hello</Checkbox>
        </div>
        
        <hr/>
        <Link to={"/posts/"}>A link to go back</Link>
        <Footer />
    </>)
}

export default Demo;
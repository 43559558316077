import './Components.css'

function InputGroup(props) {
    const {
        variant
    } = props
    
    return (<div className={"input-group flex-center column"} {...props}>

    </div>)
}

export default InputGroup